/**
 * Const with params element
 * Section with checklist component
 * Display a section with text, checklist, cta and shape
 */
/* Import section */
import React from "react"
import Checklist from "../components/checklist"
import Checklistwithcta from "../components/checklistwithcta"
import Titledecorative from "../components/titledecorative"
import Doubleshape from "../images/svg/doubleshape.inline.svg"
import Doubleshapeblue from "../images/svg/doubleshapeblue.inline.svg"
import "../styles/components/sectionchecklist.scss"

/* Declaration function */
const Sectionchecklist = ({ classSectionChecklist, titleSectionChecklist, textSectionChecklist, arrItemschecklist, ctaLabelChecklist, ctaLinkChecklist, colorChecklist, colorThemeSection, colorCtaList }) => {
  var classShape = (ctaLabelChecklist === "") ? "shape-without-cta":""
  return (
    <section className={`section-with-checklist ${classSectionChecklist} ${colorThemeSection}`}>
      <div className="wrap-checklist wrap-maincontent">
        <Titledecorative title={titleSectionChecklist} colorBar={colorChecklist} />
        <div className="box-checklist flex-box">
          <div className="intro" dangerouslySetInnerHTML={{ __html:textSectionChecklist}}/>
          <div className="wrap-list">
             {ctaLabelChecklist !== "" ?
              (
                <Checklistwithcta
                arrItems={arrItemschecklist}
                ctaLabel={ctaLabelChecklist}
                ctaLink={ctaLinkChecklist}
                colorCheck={colorChecklist}
                colorCta={colorCtaList} />
              ) : (
                <Checklist
                arrItems={arrItemschecklist}
                colorCheck={colorChecklist} />
              )}
          </div>
        </div>
        <div className={`double-shape ${classShape}`}>
          {colorThemeSection === "green" ? (<Doubleshapeblue />) : (<Doubleshape />)}
        </div>
      </div>
    </section>
  )
}


/* Export function */
export default Sectionchecklist
