/**
 * Const with params element
 * Checklist with CTA bottom component
 * Display an check list with CTA at end of the list
 */
/* Import section */
import React from "react"
import Checklist from "../components/checklist"
import Ctabuttonwitharrow from "../components/ctabuttonwitharrow"
import "../styles/components/checklistwithcta.scss"

/* Declaration function */
const Checklistwithcta = ({ arrItems, ctaLabel, ctaLink, colorCheck, colorCta }) => {
  return (
    <div className="box-checklistwithcta">
      <Checklist arrItems={arrItems} colorCheck={colorCheck} />
      <div className="cta-checklist">
        <Ctabuttonwitharrow ctaLabel={ctaLabel} ctaLink={ctaLink} colorStyle={colorCta} />
      </div>
    </div>
  )
}

/* Export function */
export default Checklistwithcta

