/**
 * Const with params element
 * Block team component
 * Display a block of team leadership
 */
/* Import section */
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import "../styles/components/blockteam.scss"

/* Declaration function */
const Blockteam= ( { keyTeam, nameTeam, titleTeam, pictureTeam} ) => {
  return (
    <div key={keyTeam} className="block-team">
      <GatsbyImage image={pictureTeam} alt={`Hello Health's Australia team member: ${nameTeam}`} />
      <div className="box-title-team">
        <h3>{nameTeam}</h3>
        <p>{titleTeam}</p>
      </div>
    </div>
  )
}

/* Export function */
export default Blockteam
